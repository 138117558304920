var Emico = Emico || {};

Emico.ThemeInit = class {
    constructor(options) {
        this.initUnveil = this.initUnveil.bind(this);
        this.initAll = this.initAll.bind(this);

        this.options = jQuery.extend({}, {
            unveilImg: 'img[data-src]'
        }, options);

        this.initAll();
    }

    initUnveil() {
        jQuery(this.options.unveilImg).unveil();
    }

    initAll() {
        jQuery(document).ready(() => {

            this.initUnveil();

        });
    }
};

jQuery(function () {
    new Emico.ThemeInit();
});